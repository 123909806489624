import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import Icons from '@aurora/shared-client/public/static/graphics/processed/enums';
import type { CSSProperties } from 'react';
import React from 'react';
import type { ClassNamesFnWrapper } from 'react-bootstrap/lib/esm/createClassNames';

interface Props {
  linkTextColor: string;
  cx: ClassNamesFnWrapper;
}

const SampleActionComponents: React.FC<Props> = ({ linkTextColor, cx }: Props) => {
  return (
    <div
      className={cx('lia-crumb-actions')}
      style={{ '--lia-breadcrumb-link-color': linkTextColor } as CSSProperties}
    >
      <Icon icon={Icons.FollowIcon} size={IconSize.PX_20} className={cx('lia-g-mr-15')} />
      <Icon icon={Icons.GearIcon} size={IconSize.PX_20} />
    </div>
  );
};

export default SampleActionComponents;
