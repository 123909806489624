import type { ProvisionalTextData } from '@aurora/shared-client/components/context/ProvisionalTextContext/ProvisionalTextContext';
import type { QuiltWrapper } from '@aurora/shared-generated/types/graphql-schema-types';
import { deepClone } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { useContext } from 'react';
import useCachedQuiltWrapper from '../../useCachedQuiltWrapper';
import type { EditedQuiltWrapper } from '../PageBuilder/types';
import PageEditorPublishContext from '../PageEditorPublishContext/PageEditorPublishContext';

interface UseQuiltWrapperToDuplicateReturn {
  /**
   * True if loading to get the data
   */
  loading: boolean;

  /**
   * Data to duplicate
   */
  data: {
    /**
     * Quilt wrapper to duplicate
     */
    quiltWrapper: QuiltWrapper;
    /**
     * If the quilt wrapper is currently edited and has texts overrides, then the session text overrides are returned else empty
     */
    texts: ProvisionalTextData[];
  };
}

export function getQuiltWrapperId(quiltWrapperId: string, templateId?: string): string {
  return templateId ? `${quiltWrapperId}_${templateId}` : quiltWrapperId;
}

/**
 * Returns the quilt wrapper to duplicate
 * @param quiltWrapperId The wrapper id
 * @param templateId the template id
 * @author Martin Sandoval
 */
export default function useQuiltWrapperToDuplicate(
  quiltWrapperId: string,
  templateId?: string
): UseQuiltWrapperToDuplicateReturn {
  const { editedQuiltWrappers } = useContext(PageEditorPublishContext);
  const sessionQuiltWrapper: EditedQuiltWrapper = editedQuiltWrappers.find(
    editedQuiltWrapper =>
      editedQuiltWrapper.quiltWrapper.id === getQuiltWrapperId(quiltWrapperId, templateId)
  );

  const { wrapper: defaultQuiltWrapper, loading: defaultQuiltWrapperLoading } =
    useCachedQuiltWrapper(
      module,
      getQuiltWrapperId(quiltWrapperId, templateId),
      null,
      false,
      !!sessionQuiltWrapper
    );

  if (sessionQuiltWrapper) {
    return {
      loading: false,
      data: {
        quiltWrapper: deepClone(sessionQuiltWrapper.quiltWrapper),
        texts: deepClone(sessionQuiltWrapper?.editedTexts) ?? []
      }
    };
  }
  return {
    loading: defaultQuiltWrapperLoading,
    data: {
      quiltWrapper: deepClone(defaultQuiltWrapper),
      texts: []
    }
  };
}
