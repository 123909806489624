import type { QuiltWrapper } from '@aurora/shared-generated/types/graphql-schema-types';
import type { QuiltFragment } from '@aurora/shared-generated/types/graphql-types';
import type { EndUserPages } from '@aurora/shared-types/pages/enums';
import React from 'react';
import type {
  EditedQuilt,
  PageEditorPublishResult,
  EditorSession,
  EditedQuiltWrapper
} from '../PageBuilder/types';

/**
 * Context intended to be used across all of Page Builder including when pageId is not present -- allows you to publish from the `Page Template` page
 * and avoid drilling props into `PageEditorPublishModal` from 2 separate `PageEditorSecondaryNav`
 */
export interface PageEditorPublishContextProps extends EditorSession {
  /**
   * Holds true if the user has any pending changes, false if not
   */
  hasChanges: boolean;
  /**
   * Contains the IDs of quilts that have been overridden.
   */
  overriddenQuiltIds?: string[];
  /**
   * Contains the IDs of quilt wrappers that have been overridden.
   */
  overriddenQuiltWrapperIds?: string[];
  /**
   * @callback called after a single page's layout is reset
   */
  onPageReset: (
    resetPageId: EndUserPages,
    resetQuilt: QuiltFragment,
    originalQuilt: QuiltFragment
  ) => Promise<void>;
  /**
   * @callback called after a quilt wrapper's layout is reset
   */
  onQuiltWrapperReset: (resetQuiltWrapper: QuiltWrapper) => Promise<void>;
  /**
   * Updates the edited quilt wrappers
   */
  setQuiltWrappers: (quiltWrappers: EditedQuiltWrapper[]) => void;
  /**
   * Updates the current set of edited quilts
   */
  setQuilts: (quilts: EditedQuilt[]) => void;
  /**
   * @callback called after cancellation is confirmed
   */
  onCancel: () => Promise<void>;
  /**
   * @callback called after page editor publish operations
   */
  onPublish: (result: PageEditorPublishResult) => Promise<void>;
}

const PageEditorPublishContext = React.createContext<PageEditorPublishContextProps>({
  editorContext: null,
  editedQuiltWrappers: [],
  editedQuilts: [],
  hasChanges: false,
  overriddenQuiltIds: [],
  overriddenQuiltWrapperIds: [],
  onPageReset: async () => {},
  onQuiltWrapperReset: async () => {},
  setQuiltWrappers: () => {},
  setQuilts: () => {},
  onCancel: async () => {},
  onPublish: async () => {}
});

export default PageEditorPublishContext;
