export enum PagerPosition {
  START = 'start',
  CENTER = 'center',
  END = 'end'
}

export enum PagerVariant {
  INFINITE_SCROLL = 'infiniteScroll',
  LOAD_MORE = 'loadMore',
  NONE = 'none',
  PREVIOUS_NEXT = 'previousNext',
  PREVIOUS_NEXT_LINKABLE = 'previousNextLinkable',
  SEE_ALL_LINK = 'seeAllLink',
  SEE_ALL_MODAL = 'seeAllModal',
  LOAD_MORE_PREVIOUS_NEXT_LINKABLE = 'loadMorePreviousNextLinkable'
}

export enum PagerLoadMoreVariant {
  BORDERED = 'bordered',
  BOXED = 'boxed',
  NONE = 'none'
}
