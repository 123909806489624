import useCachedAsset from '@aurora/shared-client/components/assets/useCachedAsset';
import useLocalizedCategoriesFeatureEnabled from '@aurora/shared-client/components/community/useLocalizedCategoriesFeatureEnabled';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useCachedQuiltWrapperBundle from '@aurora/shared-client/components/useCachedQuiltWrapperBundle';
import type { QuiltWrapper } from '@aurora/shared-generated/types/graphql-schema-types';
import { LastModifiedKeys } from '@aurora/shared-types/assets';
import { CachedAssetType } from '@aurora/shared-types/overrides/enums';
import { useContext } from 'react';
import type { ApolloError } from '@apollo/client';

interface QuiltWrapperResult {
  /**
   * The wrapper that is requested.
   */
  wrapper: QuiltWrapper;

  /**
   * Whether the request is completed or not. If completed, it is set to false.
   */
  loading: boolean;

  /**
   * The Apollo error
   */
  error?: ApolloError;
}

export const DEFAULT_QUILT_WRAPPER_ID = 'Common';

/**
 * Returns the current quilt wrapper and the default Quilt Wrapper for a page and node, or null if the quilt wrapper call is still loading.
 *
 * @param wrapperId
 * @param locale
 * @param module The module
 * @param getDefault boolean parameter to return the default Quilt Wrapper optionally.
 * @param skip whether the query should be skipped or not.
 *
 * @return a Quilt Wrapper or a default Quilt Wrapper for the provided wrapper id.
 *
 * @author Luisina Santos
 */
export default function useCachedQuiltWrapper(
  module: NodeModule | string,
  wrapperId: string,
  locale: string,
  getDefault = false,
  skip = false
): QuiltWrapperResult {
  const {
    loading: localizedCategoriesFeatureLoading,
    enabled: isLocalizedCategoriesFeatureEnabled
  } = useLocalizedCategoriesFeatureEnabled(module);

  const appContext = useContext(AppContext);
  const quiltWrapperLastModified = getDefault
    ? LastModifiedKeys.DEFAULTS_ONLY
    : appContext.quiltWrapperLastModified;
  const finalLocale = isLocalizedCategoriesFeatureEnabled ? locale : null;
  const cachedBundle = useCachedQuiltWrapperBundle(
    wrapperId,
    finalLocale,
    quiltWrapperLastModified
  );

  const finalSkip = skip || !!cachedBundle;

  const {
    loading: quiltWrapperLoading,
    data: quiltWrapperData,
    error
  } = useCachedAsset(
    wrapperId,
    CachedAssetType.WRAPPER,
    quiltWrapperLastModified,
    null,
    finalLocale,
    finalSkip
  );

  const wrapper: QuiltWrapper = cachedBundle?.value ?? quiltWrapperData?.cachedAsset?.value ?? null;

  return {
    wrapper,
    loading: !finalSkip ? localizedCategoriesFeatureLoading || quiltWrapperLoading : false,
    error
  };
}
