import useSafeColor from '@aurora/shared-client/components/useSafeColor';
import { CommonColorCssVariables } from '@aurora/shared-types/styles';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { BreadcrumbProps } from './Breadcrumb';
import localStyles from './Breadcrumb.module.pcss';

interface Props extends BreadcrumbProps {
  /**
   * The children component
   */
  children: React.ReactNode;
}

/**
 * Renders a wrapper used to set a background color for breadcrumb component
 *
 * @author Luisina Santos
 */
const BreadcrumbWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  backgroundOpacity,
  visualEffects,
  children
}: Props) => {
  const cx = useClassNameMapper(localStyles);
  const safeColor = useSafeColor();
  const finalBgColor =
    backgroundColor === CommonColorCssVariables.TRANSPARENT
      ? CommonColorCssVariables.TRANSPARENT
      : safeColor(backgroundColor)
          .alpha(backgroundOpacity / Math.pow(10, 2))
          .toString();

  return (
    <div
      style={{
        backgroundColor: finalBgColor
      }}
      className={cx({
        'border-bottom': visualEffects?.showBottomBorder
      })}
    >
      {children}
    </div>
  );
};

export default BreadcrumbWrapper;
