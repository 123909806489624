import type { ParsedUrlQuery } from '@aurora/shared-utils/helpers/urls/NextRoutes/Route';
import React from 'react';
import type { Entity } from '@aurora/shared-generated/types/graphql-schema-types';
import type { EndUserPages } from '@aurora/shared-types/pages/enums';

export interface BreadcrumbItem {
  id: string;
  title: string;
  entity?: Entity;
  /**
   * The custom route information for a breadcrumb item
   */
  routeInfo?: { route: EndUserPages; params: object; query?: ParsedUrlQuery };
}

export interface BreadcrumbContextInterface {
  /**
   * Callback function to modify the list of crumbs.
   *
   * @callback
   * @param breadcrumbs The current breadcrumbs based on the node path.
   */
  decorateBreadcrumbs(breadcrumbs: BreadcrumbItem[]): BreadcrumbItem[];
  /**
   * Action components to be used in breadcrumb
   */
  actionComponents?: React.FC<React.PropsWithChildren<unknown>>[];
}

export default React.createContext<BreadcrumbContextInterface>({
  decorateBreadcrumbs: (breadcrumbs: BreadcrumbItem[]): BreadcrumbItem[] => breadcrumbs,
  actionComponents: null
});
