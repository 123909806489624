import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { CommonColorCssVariables } from '@aurora/shared-types/styles';
import dynamic from 'next/dynamic';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { WidgetFC } from '../../common/Widget/types';
import EditContext from '../../context/EditContext/EditContext';
import { getQuiltWrapperId } from '../../pageeditor/PageEditorQuiltWrapperDuplicateModal/useQuiltWrapperToDuplicate';
import type { BreadcrumbProps } from './Breadcrumb';
import Breadcrumb from './Breadcrumb';
import localStyles from './Breadcrumb.module.pcss';
import BreadcrumbWrapper from './BreadcrumbWrapper';
import type { BreadcrumbWidgetProps } from './types';

const EditableBreadcrumbWidget = dynamic(() => import('./EditableBreadcrumbWidget'));

export interface PageHeaderBreadcrumbProps extends BreadcrumbProps {
  /**
   * Whether to render the Breadcrumb
   */
  useBreadcrumb?: boolean;
  /**
   * Whether the Breadcrumb is transparent
   */
  useTransparentBreadcrumb?: boolean;
  /**
   * Whether to render the Breadcrumb below the NodeHeader. Defaults to false
   */
  renderBreadcrumbLast?: boolean;
}

export const defaultBreadcrumbWidgetProps: BreadcrumbWidgetProps = {
  linkTextColor: CommonColorCssVariables.GRAY_700,
  linkHighlightColor: CommonColorCssVariables.GRAY_600,
  backgroundColor: CommonColorCssVariables.TRANSPARENT,
  backgroundOpacity: 100,
  visualEffects: {
    showBottomBorder: true
  }
};

/**
 * Community Breadcrumb Widget
 *
 * @author Luisina Santos
 */
const BreadcrumbWidget: WidgetFC<BreadcrumbWidgetProps> = (
  breadcrumbProps: BreadcrumbWidgetProps
) => {
  const { quilt: isEditMode } = useContext(EditContext);
  const cx = useClassNameMapper(localStyles);
  const { router } = useEndUserRoutes();
  const quiltWrapperId: string = router.getUnwrappedQueryParam(
    EndUserQueryParams.QUILT_WRAPPER_ID,
    null
  );
  const templateId: string = router.getUnwrappedQueryParam(
    EndUserQueryParams.QUILT_WRAPPER_TEMPLATE_ID,
    null
  );
  const wrapperId = getQuiltWrapperId(quiltWrapperId, templateId);

  const {
    backgroundColor = defaultBreadcrumbWidgetProps.backgroundColor,
    backgroundOpacity = defaultBreadcrumbWidgetProps.backgroundOpacity,
    linkTextColor = defaultBreadcrumbWidgetProps.linkTextColor,
    linkHighlightColor = defaultBreadcrumbWidgetProps.linkHighlightColor,
    visualEffects = defaultBreadcrumbWidgetProps.visualEffects
  } = breadcrumbProps;

  const defaultBreadcrumbProps: PageHeaderBreadcrumbProps = {
    useTransparentBreadcrumb: false,
    renderBreadcrumbLast: false
  };
  const finalBreadcrumbProps: PageHeaderBreadcrumbProps = Object.assign(
    defaultBreadcrumbProps,
    breadcrumbProps
  );
  const {
    disableLastCrumbForDesktop,
    useParentToNavigateForMobile,
    className: breadcrumbClassName
  } = finalBreadcrumbProps;

  return (
    <div className={cx('lia-breadcrumb-container')}>
      <BreadcrumbWrapper
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        visualEffects={visualEffects}
      >
        {!!wrapperId ? (
          <EditableBreadcrumbWidget
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...finalBreadcrumbProps}
            linkTextColor={linkTextColor}
            linkHighlightColor={linkHighlightColor}
          />
        ) : (
          <Breadcrumb
            className={cx(
              'lia-breadcrumb-container-content',
              { 'lia-breadcrumb-edit-mode': isEditMode },
              breadcrumbClassName
            )}
            disableLastCrumbForDesktop={disableLastCrumbForDesktop}
            useParentToNavigateForMobile={useParentToNavigateForMobile}
            linkTextColor={linkTextColor}
            linkHighlightColor={linkHighlightColor}
          />
        )}
      </BreadcrumbWrapper>
    </div>
  );
};

export default BreadcrumbWidget;
