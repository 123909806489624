import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import type { Message } from '@aurora/shared-generated/types/graphql-schema-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { getLog } from '@aurora/shared-utils/log';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { NodeLinkFragment, UserLinkFragment } from '../../../types/graphql-types';
import MessageLink from '../../messages/MessageLink/MessageLink';
import NodeLink from '../../nodes/NodeLink/NodeLink';
import UserLink from '../../users/UserLink/UserLink';

const log = getLog(module);

interface Props {
  /**
   * The entity to create the link for.
   */
  entity: UserLinkFragment | NodeLinkFragment | Message;
  /**
   * Children to display inside the link.
   */
  children?: React.ReactNode;
  /**
   * Whether to pass the href to the children component, only needed when children component is not an `<a>`.
   * This parameter is passed directly to the underlying `next/Link` component.
   *
   * See more here: https://nextjs.org/docs/api-reference/next/link
   */
  passHref?: boolean;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the link element.
   */
  className?: string;
  /**
   * Use hover card for links.
   */
  useHoverCard?: boolean;
}

/**
 * Creates a NextJS link for a LIA entity.
 *
 * @constructor
 *
 * @author Adam Ayres
 */
const EntityLink: React.FC<React.PropsWithChildren<Props>> = ({
  entity,
  children,
  passHref = false,
  as,
  className,
  useHoverCard = false
}) => {
  const cx = useClassNameMapper();
  const tenant = useContext(TenantContext);
  const { type } = IdConverter.decodeIdAsParts(tenant, entity.id);
  if (type === 'user') {
    return (
      <UserLink
        user={entity as UserLinkFragment}
        as={as}
        className={cx(className)}
        useHoverCard={useHoverCard}
      >
        {children}
      </UserLink>
    );
  }

  if (Object.values(NodeType).includes(type as NodeType)) {
    return (
      <NodeLink node={entity as NodeLinkFragment} as={as} className={cx(className)}>
        {children}
      </NodeLink>
    );
  }
  if (type === 'message') {
    return (
      <MessageLink
        message={entity as Message}
        as={as}
        passHref={passHref}
        className={cx(className)}
      >
        {children}
      </MessageLink>
    );
  }

  log.error('No link registered from entity type: [%s]', type);
  return <>{children}</>;
};

export default EntityLink;
