import { buildPrefixedQuiltWrapperBundleId } from '@aurora/shared-apollo/helpers/ApolloQuiltWrapperCacheHelper';
import type { CachedAssetFragment } from '@aurora/shared-generated/types/graphql-types';
import { useContext } from 'react';
import TenantContext from './context/TenantContext';
import useCachedFragment from './useCachedFragment';

/**
 * Hook for fetch a quilt wrapper bundle from the Apollo cache
 * @param wrapperId The ID of the wrapper
 * @param locale the locale
 * @param lastModified Last modified timestamp
 *
 * @returns Cached quilt wrapper, or null
 *
 * @author Luisina Santos
 */
export default function useCachedQuiltWrapperBundle(
  wrapperId: string,
  locale: string,
  lastModified: string
): CachedAssetFragment {
  const tenant = useContext(TenantContext);
  const bundleId = buildPrefixedQuiltWrapperBundleId(tenant.id, wrapperId, locale, lastModified);

  return useCachedFragment(bundleId);
}
